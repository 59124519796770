<template>
  <page :styleWrap='{background: "#fff"}'>
    <div class="suggestPage">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="title">问题类别</div>
      <div class="f_flex f_a_c suggpanel">
        <div 
          class="suggItem f_flex f_a_c f_j_c" 
          v-for='(item, index) in suggList'
          :class="{'suggAct': suggAct === index}" 
          @click='pickSuggItem(index)'
          :key='index'>
          {{item}}
        </div>
      </div>

      <div class="title">问题说明</div>
      <textarea 
        name="desc" 
        placeholder="请填写详细说明，以便我们更好的为您解决问题" 
        class="textArea" 
        v-model='desc'>
      </textarea>

      <div class="title">联系方式<span style="color: #9B9B9B">（选填）</span></div>
      <input type="text" v-model="contact" class="contactInput" placeholder="请填写手机号或邮箱，以便我们与您联系">

      <button @click='submitFn' class="btn btn_radius margin_auto theme_bg suggestBtn">提交</button>
    </div>
  </page>
</template>

<script>
import suggestApi from '@/api/other'

export default {
  data: ()=> ({
    suggList: ['建议', '故障', '其他'],
    suggAct: 0,
    desc: '',
    contact: ''
  }),
  methods: {
    pickSuggItem(index) {
      this.suggAct = index
    },
    submitFn() {
      if(this.desc) {
        suggestApi.feedback({
          contactInfo: this.contact,
          feedbackType: this.suggAct+1,
          content: this.desc
        }).then(res => {
          if (res.data) {
            this.$dialog({ message: res.data.msg }).then(()=> {
              this.desc = ''
              this.$router.back()
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.suggestPage {
  color: #2D2D2D;
  padding: 0 15px;
  .title {
    font-size: 15px;
    margin-top: 13px;
    margin-bottom: 18px;
    font-weight: 400;
  }
  .suggpanel {
    margin-bottom: 30px;
  }
  .suggItem {
    font-size: 14px;
    width: 76px;
    height: 36px;
    background: #F6F6F6;
    margin-right: 20px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 40px;
  }
  .suggAct {
    color: #4F92E6;
    border: 1px solid #4F92E6;
    background: #fff;
  }
  .textArea {
    width: 100%;
    box-sizing: border-box;
    height: 157px;
    padding: 13px 15px;
    color: #2D2D2D;
    font-size: 14px;
    border: 1px solid #E4E4E4;
    margin-bottom: 15px;
    resize: none;
    border-radius: 3px;
  }
  .contactInput {
    width: 100%;
    padding: 14px 20px;
    border: 1px solid #E4E4E4;
    font-size: 14px;
    color: #2d2d2d;
    border-radius: 3px;
    box-sizing: border-box;
  }
  .suggestBtn {
    width: 80%;
    margin-top: 84px;
  }
}
</style>